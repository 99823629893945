import { omit } from 'lodash-es';
import { NextRouter } from 'next/router';

export function routerOmitReplace(
  router: NextRouter,
  omitParam: string | string[],
) {
  router.replace(
    {
      pathname: router.pathname,
      query: omit(router.query, omitParam),
    },
    undefined,
    { shallow: true },
  );
}

export function getAccountURL(accountId: string, desiredPathname?: string) {
  let pathname = '/[accountId]';

  if (desiredPathname && desiredPathname.startsWith('/[accountId]')) {
    // we cut down to first non accountId param
    // desiredPathname: /[accountId]/some-page/[someId]/details
    // new pathname: /[accountId]/some-page
    const index = desiredPathname.indexOf('/[', 1);

    pathname = index > -1 ? desiredPathname.slice(0, index) : desiredPathname;
  }

  return {
    pathname,
    query: { accountId },
  };
}

export function getEngineCreateURL(accountId: string) {
  return {
    pathname: '/[accountId]/engines/create',
    query: { accountId },
  };
}

export function getEnginesURL(accountId: string) {
  return {
    pathname: '/[accountId]/engines',
    query: { accountId },
  };
}

export function getWorksheetsURL(accountId: string) {
  return {
    pathname: '/[accountId]/worksheets',
    query: { accountId },
  };
}

export function getDatabaseCreateURL(accountId: string) {
  return {
    pathname: '/[accountId]/databases/create',
    query: { accountId },
  };
}

export function getDatabasesURL(accountId: string) {
  return {
    pathname: '/[accountId]/databases',
    query: { accountId },
  };
}

export function getDatabaseURL(databaseId: string, accountId: string) {
  return {
    pathname: '/[accountId]/databases/[dbId]',
    query: { accountId, dbId: databaseId },
  };
}

export function getSettingsURL(accountId: string) {
  return {
    pathname: '/[accountId]/settings',
    query: { accountId },
  };
}

export function getNotebookURL(
  databaseId: string,
  accountId: string,
  notebookId: string,
) {
  const dbLink = getDatabaseURL(databaseId, accountId);

  return {
    pathname: `${dbLink.pathname}/notebooks/[notebookId]`,
    query: { ...dbLink.query, notebookId },
  };
}

export function getTxnURL(
  txnId: string,
  accountId: string,
  view?: string,
  search?: string,
) {
  return {
    pathname: '/[accountId]/transactions/[txnId]',
    query: {
      accountId,
      txnId,
      ...(view ? { view } : {}),
      ...(search ? { search } : {}),
    },
  };
}

export function getTxnsURL(accountId: string) {
  return {
    pathname: '/[accountId]/transactions',
    query: { accountId },
  };
}

export function getClientURL(clientId: string, accountId: string) {
  return {
    pathname: '/[accountId]/settings/clients/[clientId]',
    query: { accountId, clientId },
  };
}

export function getClientsURL(accountId: string) {
  return {
    pathname: '/[accountId]/settings/clients',
    query: { accountId },
  };
}

export function getUserURL(userId: string, accountId: string) {
  return {
    pathname: '/[accountId]/settings/users/[userId]',
    query: { accountId, userId },
  };
}

export function getUsersURL(accountId: string) {
  return {
    pathname: '/[accountId]/settings/users',
    query: { accountId },
  };
}

export function getSettingsAccountsURL(accountId: string) {
  return {
    pathname: '/[accountId]/settings/accounts',
    query: { accountId },
  };
}

export function getSettingsAccountURL(
  accountId: string,
  settingsAccountId: string,
  accountName: string,
) {
  return {
    pathname: '/[accountId]/settings/accounts/[settingsAccountId]',
    query: { accountId, settingsAccountId, accountName },
  };
}

const DATADOG_LOG_RETENTION_PERIOD_MS = 30 * 24 * 60 * 60 * 1000;

export function getDatadogLogsURL(transactionId: string) {
  const fromTS = Date.now() - DATADOG_LOG_RETENTION_PERIOD_MS;

  return `https://app.datadoghq.com/logs?query=%40rai.transaction_id%3A${transactionId}&from_ts=${fromTS}`;
}

export function getDatadogTraceURL(transactionId: string) {
  const fromTS = Date.now() - DATADOG_LOG_RETENTION_PERIOD_MS;

  return `https://app.datadoghq.com/apm/traces?query=%40_top_level%3A1%20%40rai.transaction_id%3A${transactionId}&start=${fromTS}&historicalData=true`;
}

export function getDatadogDashboardURL(
  transactionID: string,
  engineName: string,
  accountID: string,
) {
  const fromTS = Date.now() - DATADOG_LOG_RETENTION_PERIOD_MS;

  return `https://app.datadoghq.com/dashboard/nt6-ze5-6a4/wip-transaction-details-dashboard?tpl_var_account_name%5B0%5D=${accountID}&tpl_var_transaction_id%5B0%5D=${transactionID}&tpl_var_engine_name%5B0%5D=${engineName}&from_ts=${fromTS}&live=true`;
}

export function getEditorModelURL(
  accountId: string,
  databaseName: string,
  modelName: string,
  modelPos?: string,
  bottomTabId?: string,
) {
  return {
    pathname: '/[accountId]/editor',
    query: {
      accountId,
      databaseName,
      modelName,
      ...(modelPos ? { modelPos } : {}),
      ...(bottomTabId ? { bottomTabId } : {}),
    },
  };
}

export function getEditorBaseRelationURL(
  accountId: string,
  databaseName: string,
  baseRelationName: string,
) {
  return {
    pathname: '/[accountId]/editor',
    query: {
      accountId,
      databaseName,
      baseRelationName,
    },
  };
}

export function getEditorWorksheetURL(
  accountId: string,
  worksheetId: string,
  worksheetPos?: string,
) {
  return {
    pathname: '/[accountId]/editor',
    query: {
      accountId,
      worksheetId,
      ...(worksheetPos ? { worksheetPos } : {}),
    },
  };
}

export function getEditorURL(accountId: string) {
  return {
    pathname: '/[accountId]/editor',
    query: {
      accountId,
    },
  };
}
