import { datadogRum } from '@datadog/browser-rum';
import { isFunction } from 'lodash-es';

export const logAction = (
  message: string,
  callback?: Function,
  context?: object | undefined,
) => {
  datadogRum.addAction(message, context);

  if (isFunction(callback)) {
    return callback();
  }
};

export const logKeyStroke = (
  key: string,
  action?: string,
  callback?: Function,
) => {
  const context = {
    keyStroke: key.toLowerCase(),
    action,
  };

  const actionMessage = `KeyStroke: ${
    action ? `${key.toLowerCase()} - ${action}` : key.toLowerCase()
  }`;

  return logAction(actionMessage, callback, context);
};
