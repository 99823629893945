import Link from 'next/link';
import { useRouter } from 'next/router';
import { ComponentProps, useState } from 'react';
import { RiBook2Line, RiMenuFill } from 'react-icons/ri';

import logoImage from '../../../public/images/full-relationalai-logo.png';
import useAccount from '../../hooks/useAccount';
import { AccountSelect } from './AccountSelect';
import { GlobalEngineSelect } from './GlobalEngineSelect';
import MobileMenu from './MobileMenu';
import { UserSelect } from './UserSelect';

type NavBarProps = Pick<ComponentProps<typeof MobileMenu>, 'navItems'> &
  Pick<ComponentProps<typeof UserSelect>, 'user' | 'logout'>;

export default function NavBar({ navItems, user, logout }: NavBarProps) {
  const [open, setOpen] = useState(false);
  const { accounts, accountId: currentAccountId } = useAccount();
  const router = useRouter();
  const isEngineMenuVisible = router.route.startsWith(
    '/[accountId]/databases/[dbId]',
  );

  return (
    <header className='flex-shrink-0 relative h-16 bg-white flex items-center border-gray-300 border-b z-20'>
      {/* Logo area */}
      <div className='absolute inset-y-0 left-0 lg:static lg:flex-shrink-0'>
        <Link
          href='/'
          className='flex items-center justify-center h-full w-48 bg-white focus:outline-none pl-4'
        >
          <img
            src={logoImage.src}
            alt='Relational AI Console'
            width={176}
            height={25}
          />
        </Link>
      </div>

      {/* Menu button area */}
      <div className='absolute inset-y-0 right-0 pr-4 flex items-center sm:pr-6 lg:hidden'>
        {/* Mobile menu button */}
        <button
          type='button'
          className='-mr-2 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-600'
          onClick={() => setOpen(true)}
        >
          <span className='sr-only'>Open main menu</span>
          <RiMenuFill className='block h-6 w-6' aria-hidden='true' />
        </button>
      </div>

      {/* Desktop nav area */}
      <div className='hidden lg:min-w-0 lg:flex-1 lg:flex lg:items-center lg:justify-between'>
        <div className='flex gap-4 ml-4'></div>
        <div className='ml-10 pr-4 flex-shrink-0 flex items-center space-x-8'>
          <AccountSelect
            currentAccountId={currentAccountId}
            accounts={accounts}
          />
          {isEngineMenuVisible && (
            <GlobalEngineSelect accountId={currentAccountId} />
          )}

          <a
            href='http://docs.relational.ai'
            className='hover:text-gray-900'
            rel='noreferrer'
            target='_blank'
          >
            <div className='flex gap-2 items-center text-sm leading-6 text-gray-600 hover:text-gray-900'>
              <RiBook2Line className='h-5 w-5' />
              <span>Docs</span>
            </div>
          </a>
          <UserSelect user={user} logout={logout} />
        </div>
      </div>

      <MobileMenu
        open={open}
        setOpen={setOpen}
        navItems={navItems}
        currentAccountId={currentAccountId}
        accounts={accounts}
        user={user}
      />
    </header>
  );
}
