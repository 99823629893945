import { Dialog, Transition } from '@headlessui/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Fragment, KeyboardEvent } from 'react';
import { RiBookOpenLine, RiCloseLine } from 'react-icons/ri';

import { RoleGuard } from '../auth/RoleGuard';
import { AccountSelect } from './AccountSelect';
import { GlobalEngineSelect } from './GlobalEngineSelect';
import { NavItem } from './Sidebar';
import { User } from './UserSelect';

const userNavigation = [{ name: 'Sign out', href: '#' }];

type MobileMenuProps = {
  navItems: NavItem[];
  open: boolean;
  setOpen: (o: boolean) => void;
  user: User;
  currentAccountId: string;
  accounts: string[];
};

type MobileMenuItemProps = {
  item: NavItem;
  setOpen: (o: boolean) => void;
};

function MobileMenuItem({ item, setOpen }: MobileMenuItemProps) {
  const commonProps = {
    className:
      'flex gap-4 rounded-md py-2 px-3 text-base font-medium text-gray-700 hover:bg-gray-100',
    onClick: () => {
      setOpen(false);

      item.onClick && item.onClick();
    },
    onKeyDown: (event: KeyboardEvent<HTMLElement>) =>
      event.key === 'Enter' && setOpen(false),
  };
  const innerEl = (
    <>
      <item.icon className='h-6 w-6' aria-hidden='true' />
      {item.name}
    </>
  );

  let itemElement = item.href ? (
    <Link href={item.href} {...commonProps}>
      {innerEl}
    </Link>
  ) : (
    <button type='button' {...commonProps}>
      {innerEl}
    </button>
  );

  if (item.roles) {
    itemElement = (
      <RoleGuard key={item.name} roles={item.roles}>
        {itemElement}
      </RoleGuard>
    );
  }

  return itemElement;
}

export default function MobileMenu({
  navItems,
  open,
  setOpen,
  user,
  currentAccountId,
  accounts,
}: MobileMenuProps) {
  const router = useRouter();
  const isEngineMenuVisible = router.route.startsWith(
    '/[accountId]/databases/[dbId]',
  );

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as='div'
        static
        className='fixed inset-0 z-40 lg:hidden'
        open={open}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter='transition-opacity ease-linear duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='transition-opacity ease-linear duration-300'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='hidden sm:block sm:fixed sm:inset-0 sm:bg-gray-600 sm:bg-opacity-75' />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter='transition ease-out duration-150 sm:ease-in-out sm:duration-300'
          enterFrom='transform opacity-0 scale-110 sm:translate-x-full sm:scale-100 sm:opacity-100'
          enterTo='transform opacity-100 scale-100 sm:translate-x-0 sm:scale-100 sm:opacity-100'
          leave='transition ease-in duration-150 sm:ease-in-out sm:duration-300'
          leaveFrom='transform opacity-100 scale-100 sm:translate-x-0 sm:scale-100 sm:opacity-100'
          leaveTo='transform opacity-0 scale-110 sm:translate-x-full sm:scale-100 sm:opacity-100'
        >
          <Dialog.Panel
            className='fixed z-40 inset-0 h-full w-full bg-white sm:inset-y-0 sm:left-auto sm:right-0 sm:max-w-sm sm:w-full sm:shadow-lg'
            aria-label='Global'
          >
            <div className='h-16 flex items-center justify-between px-4 sm:px-6'>
              <a href='https://relational.ai'>
                <img
                  className='block h-8 w-auto'
                  src='/images/rai_logo_square.png'
                  alt='Console'
                />
              </a>
              <button
                type='button'
                className='-mr-2 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-600'
                onClick={() => setOpen(false)}
              >
                <span className='sr-only'>Close main menu</span>
                <RiCloseLine className='block h-6 w-6' aria-hidden='true' />
              </button>
            </div>

            <div className='max-w-8xl mx-auto py-3 px-2 sm:px-4'>
              {navItems.map(item => (
                <MobileMenuItem key={item.name} item={item} setOpen={setOpen} />
              ))}

              <a
                href='http://docs.relational.ai'
                className='flex gap-4 rounded-md py-2 px-3 text-base font-medium text-gray-700 hover:bg-gray-100'
                rel='noreferrer'
                target='_blank'
              >
                <RiBookOpenLine className='h-6 w-6' />
                <span>Docs</span>
              </a>
            </div>
            <div className='max-w-8xl mx-auto mb-4 px-2 sm:px-4 flex flex-col gap-4'>
              <AccountSelect
                currentAccountId={currentAccountId}
                accounts={accounts}
              />
              {isEngineMenuVisible && (
                <GlobalEngineSelect accountId={currentAccountId} />
              )}
            </div>

            <div className='border-t border-gray-200 pt-4 pb-3'>
              <div className='max-w-8xl mx-auto px-4 flex items-center sm:px-6'>
                <div className='flex-shrink-0'>
                  <img
                    className='h-10 w-10 rounded-full'
                    src={user.picture}
                    alt=''
                  />
                </div>
                <div className='ml-3 min-w-0 flex-1'>
                  <div className='text-base font-medium text-gray-800 truncate'>
                    {user.name}
                  </div>
                  <div className='text-sm font-medium text-gray-500 truncate'>
                    {user.email}
                  </div>
                </div>
              </div>
              <div className='mt-3 max-w-8xl mx-auto px-2 space-y-1 sm:px-4'>
                {userNavigation.map(item => (
                  <a
                    key={item.name}
                    href={item.href}
                    className='block rounded-md py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-50'
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
          </Dialog.Panel>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
}
