import { useContext, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { ModalContext } from '../components/modals/ModalProvider';

type CloseFn = () => void;

export default function useModal<T>(
  createModal: (onClose: CloseFn, openProp?: T | undefined) => JSX.Element,
) {
  const context = useContext(ModalContext);
  const [id] = useState(uuid());

  const closeModal = () => {
    context.closeModal(id);
  };

  const openModal = (openProps?: T) => {
    context.openModal(id, createModal(closeModal, openProps));
  };

  return {
    openModal,
    closeModal,
  };
}
