import { useAuth0 } from '@auth0/auth0-react';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum, RumErrorEvent } from '@datadog/browser-rum';
import { useEffect } from 'react';

import { ACCOUNT_KEY, RaiUser } from '../components/auth/raiUser';

const datadogSettings = {
  clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN as string,
  service: 'rai-console',
  // NEXT_PUBLIC_DATADOG_ENV should be set in the host's environment variables.
  // Falls back to `dev` if not found, ensuring all development
  // and localhost systems use the `dev` rather than `prod` environment.
  env: process.env.NEXT_PUBLIC_DATADOG_ENV
    ? process.env.NEXT_PUBLIC_DATADOG_ENV
    : 'dev',
  // Specify a version number to identify the deployed
  // version of your application in Datadog
  version: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA as string, //'1.0.0',
  useSecureSessionCookie: true,
  // Session recording
  sampleRate: 100,
  sessionReplaySampleRate: 100,
  trackResources: true,
  trackLongTasks: true,
};

datadogRum.init({
  ...datadogSettings,
  applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID as string,
  allowedTracingOrigins: [process.env.NEXT_PUBLIC_BASE_URL as string],
  trackInteractions: true,
  trackViewsManually: true,

  beforeSend: e => {
    if (e.type === 'error') {
      const { error } = e as RumErrorEvent;

      // Ignoring ResizeObserer error
      // according to this https://github.com/TanStack/virtual/issues/531#issuecomment-1698062496
      if (
        error.message?.includes(
          'ResizeObserver loop completed with undelivered notifications',
        )
      ) {
        return false;
      }

      // Ignoring Launchdarkly errors
      if (
        error.message?.includes('LD: [error]') ||
        error.message?.includes('[LaunchDarkly] network error') ||
        error.type === 'LaunchDarklyFlagFetchError'
      ) {
        return false;
      }

      // Ignoring Next.js route errors
      if (
        error.source === 'console' &&
        (error.message?.includes('Abort fetching component for route') ||
          error.message?.includes('Failed to load script') ||
          error.message?.includes('Route did not complete loading'))
      ) {
        return false;
      }

      // Ignoring Google Tag Manager errors since it is coming from a third-party script
      if (
        error.message?.includes(
          'TypeError: Cannot redefine property: googletag',
        )
      ) {
        return false;
      }

      return true;
    }
  },
});

datadogLogs.init({
  ...datadogSettings,
});

export function useUserAnalytics() {
  const { user } = useAuth0<RaiUser>();

  useEffect(() => {
    if (user) {
      datadogRum.setUser({
        email: user.email,
        'rai.account_name': user[ACCOUNT_KEY],
      });

      datadogLogs.setLoggerGlobalContext({
        email: user.email,
        'rai.account_name': user[ACCOUNT_KEY],
      });

      // Only record internal accounts
      if (user && user[ACCOUNT_KEY].startsWith('relationalai')) {
        datadogRum.startSessionReplayRecording();
      } else {
        datadogRum.stopSessionReplayRecording();
      }
    }
  }, [user]);
}
