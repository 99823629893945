import { BsSearch } from 'react-icons/bs';
import AutoSizer from 'react-virtualized-auto-sizer';

import queryEditorHeader from '../../../public/images/queryEditorHeader.png';

const tallBreakpoint = 650;

type NoDataFoundProps = {
  text?: string;
};

export function NoDataFound({
  text = 'No data found matching your criteria',
}: NoDataFoundProps) {
  return (
    <AutoSizer disableWidth>
      {({ height }) => (
        <div className='flex flex-col justify-center h-full'>
          <div
            className={'flex flex-col w-full gap-10'}
            data-testid='nodata-overlay'
          >
            {height > tallBreakpoint && (
              <img
                alt='no data found'
                src={queryEditorHeader.src}
                data-testid='nodata-image'
                className='h-auto m-auto opacity-20 grayscale flex'
              />
            )}
            {height > 200 && (
              <div className='flex-col w-full text-center justify-center text-sm font-sans text-gray-400'>
                {height > 500 && (
                  <div className='w-full text-center justify-center flex'>
                    <BsSearch
                      data-testid='nodata-icon'
                      className='h-24 w-24 mr-1 -mt-20 text-red-orange-300 mb-5'
                    />
                  </div>
                )}
                <div>{text}</div>
              </div>
            )}
          </div>
        </div>
      )}
    </AutoSizer>
  );
}
