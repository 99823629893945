import { orderBy } from 'lodash-es';
import { useRouter } from 'next/router';

import { Dropdown } from '@relationalai/ui';

import { getAccountURL } from '../../utils/urlHelper';
import { estimateSize } from '../dropdown/dropdownHelpers';
import { Option, ResourceOption } from '../dropdown/ResourceOption';
import { SelectTrigger } from './SelectTrigger';

type AccountSelectProps = {
  currentAccountId: string;
  accounts: string[];
};

export function AccountSelect({
  currentAccountId,
  accounts,
}: AccountSelectProps) {
  const router = useRouter();
  const options = orderBy(
    accounts.map(id => ({
      label: id,
      value: id,
      subText: 'us-east',
    })),
    ['label'],
  );

  if (!currentAccountId) {
    return null;
  }

  const handleSelect = (value: string) => {
    router.push(getAccountURL(value, router.pathname));
  };

  return (
    <Dropdown<Option>
      triggerElement={
        <SelectTrigger
          triggerText={currentAccountId}
          tooltipText={currentAccountId}
          testIdPrefix='account-select'
          label={'Account'}
        />
      }
      OptionComponent={ResourceOption}
      onSelect={handleSelect}
      selected={currentAccountId}
      testIdPrefix='account-select'
      options={options}
      scrollHeight={476}
      estimateSize={estimateSize}
    />
  );
}
