import classNames from 'classnames';
import { partition } from 'lodash-es';
import Link from 'next/link';
import { FC, SVGProps } from 'react';

import { UserRole } from '@relationalai/console-state';
import { Tooltip } from '@relationalai/ui';

import { RoleGuard } from '../auth/RoleGuard';

export type NavItem = {
  name: string;
  testId: string;
  icon: FC<SVGProps<SVGSVGElement>>;
  current?: boolean;
  roles?: UserRole[];
  badge?: string;
  bottom?: boolean;
} & ConditionalNavItem;

type ConditionalNavItem =
  | {
      href?: string;
      onClick?: never;
    }
  | {
      href?: never;
      onClick?: () => void;
    };

type NavItemProps = {
  item: NavItem;
};

type SidebarProps = {
  navItems: NavItem[];
};

function SidebarItem({ item }: NavItemProps) {
  const innerEl = (
    <span>
      <span className='sr-only'>{item.name}</span>
      <item.icon className='h-8 w-8' aria-hidden='true' />
      {item.badge && (
        <div
          className={classNames(
            'absolute -bottom-[3px] right-0 left-0 w-fit m-auto px-2 p-0.5 text-[0.8em] font-semibold rounded-md text-white',
            item.current ? 'bg-red-orange-900' : 'bg-gray-300',
          )}
        >
          {item.badge}
        </div>
      )}
    </span>
  );
  const commonProps = {
    'data-testid': `sidebar-item-${item.testId}`,
    className: classNames(
      'relative hover:bg-gray-200',
      item.current ? 'text-red-orange-900' : 'text-neutral-300',
      'flex-shrink-0 inline-flex items-center justify-center h-14 w-14 rounded-lg',
    ),
  };
  let itemElement = (
    <div className='mt-2'>
      <Tooltip text={item.name} placement='right'>
        {item.href ? (
          <Link href={item.href} {...commonProps}>
            {innerEl}
          </Link>
        ) : (
          <button type='button' onClick={item.onClick} {...commonProps}>
            {innerEl}
          </button>
        )}
      </Tooltip>
    </div>
  );

  if (item.roles) {
    itemElement = (
      <RoleGuard key={item.name} roles={item.roles}>
        {itemElement}
      </RoleGuard>
    );
  }

  return itemElement;
}

export default function Sidebar({ navItems }: SidebarProps) {
  const [bottomGroup, topGroup] = partition(navItems, { bottom: true });

  return (
    <nav
      aria-label='Sidebar'
      className='hidden lg:block lg:flex-shrink-0 lg:bg-gray-100 lg:overflow-y-visible border-r-2 border-gray-200'
    >
      <div className='relative w-20 flex flex-col p-3 justify-between h-full pb-5 bg-gray-50'>
        <div>
          {topGroup.map((item: NavItem) => (
            <SidebarItem key={item.name} item={item} />
          ))}
        </div>
        {bottomGroup && (
          <div>
            {bottomGroup.map((item: NavItem) => (
              <SidebarItem key={item.name} item={item} />
            ))}
          </div>
        )}
      </div>
    </nav>
  );
}
