import { useAuth0 } from '@auth0/auth0-react';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

import { Spinner } from '@relationalai/ui';

import { useGetToken } from '../../hooks/useSdkClient';
import { ACCOUNT_KEY, RaiUser } from '../auth/raiUser';

export type ClaimsLoaderProps = {
  children: JSX.Element;
};

export function ClaimsLoader({ children }: ClaimsLoaderProps) {
  const router = useRouter();
  const accountId = router.query.accountId as string | undefined;
  const getToken = useGetToken(accountId || '');

  const { user } = useAuth0<RaiUser>();

  const isAccountIdDifferent =
    accountId && user && accountId !== user[ACCOUNT_KEY];

  useEffect(() => {
    // it is necessary to update user claims when switching accounts
    if (isAccountIdDifferent) {
      getToken();
    }
    // no dependencies for useEffect is on purpose
    // this fixes the issue when the user switches between accounts too fast
    // Auth0 can ignore getAccessTokenSilently for the last account
    // if the previous call is still pending
    // useEffect won't be fired again if we have [accountId] as the dependency
  });

  if (isAccountIdDifferent) {
    return (
      <div className='h-full flex items-center justify-center absolute inset-0 flex-col gap-4'>
        {!!user[ACCOUNT_KEY] && (
          <div className='text-gray-400 font-medium text-lg'>
            Switching to {accountId}
          </div>
        )}

        <Spinner size='xl' />
      </div>
    );
  } else {
    return children;
  }
}
