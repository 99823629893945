import { Combobox } from '@headlessui/react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useCallback, useRef, useState } from 'react';
import { RiAddFill } from 'react-icons/ri';

import { useEngineStore } from '@relationalai/console-state';
import { EngineState } from '@relationalai/console-state/src/engines/engineStore';
import { Dropdown } from '@relationalai/ui';

import useModal from '../../hooks/useModal';
import { estimateSize } from '../dropdown/dropdownHelpers';
import { Option, ResourceOption } from '../dropdown/ResourceOption';
import { CreateEngineModal } from '../engines/CreateEngineModal';
import { SelectTrigger } from './SelectTrigger';

type GlobalEngineProps = {
  accountId: string;
};

export const GlobalEngineSelect = observer(function GlobalEngineSelect({
  accountId,
}: GlobalEngineProps) {
  const inputReference = useRef<HTMLInputElement>(null);
  const [search, setSearch] = useState('');
  const engineStore = useEngineStore(accountId);
  const { openModal } = useModal(onClose => (
    <CreateEngineModal
      accountId={accountId}
      onClose={onClose}
      onModify={handleSelect}
    />
  ));
  const selectedEngine = engineStore.selectedEngine;
  const engines = engineStore.engines.filter(
    e =>
      e.state === EngineState.PROVISIONED ||
      e.state === EngineState.PROVISIONING,
  );

  const createItem: Option = {
    icon: RiAddFill,
    label: 'Create Engine',
    value: '$$ create $$',
  };

  const getFilteredEngines = useCallback(() => {
    const lowerCaseSearch = search.toLowerCase();

    return engines.filter(e => e.name.toLowerCase().includes(lowerCaseSearch));
  }, [search, engines]);

  const { isLoading, isLoaded } = engineStore;

  const handleSelect = (value: string) => {
    if (value === createItem.value) {
      openModal();
    } else {
      engineStore.setSelectedEngine(value);
    }
  };

  const focusInput = () => {
    inputReference.current?.focus();
  };

  const children = (
    <div className='py-4 px-4 bg-white bottom-b-gray-200 border-b'>
      <div className='flex rounded-md shadow-sm relative border-gray-300'>
        <Combobox.Input
          autoComplete='off'
          ref={inputReference}
          className={classNames(
            'flex-grow sm:text-sm rounded-md px-3 py-2',
            'read-only:opacity-50 placeholder-gray-400 focus:outline-red-orange-700 focus-visible:outline-red-orange-700',
            'border border-gray-300 ring-gray-300',
            'focus:ring-offset-0 focus:shadow-none focus:ring-0 focus:border-none',
          )}
          onChange={e => setSearch(e.target.value)}
          aria-label='Select Engine'
          name='search-engine'
          defaultValue={search}
          placeholder='Search...'
          data-dd-action-name='Engine Select Search'
        />
      </div>
    </div>
  );

  const options: Option[] = getFilteredEngines().map(option => {
    const { name, size, state, region } = option;

    return {
      label: name,
      value: name,
      subText:
        state === EngineState.PROVISIONED
          ? `${size || ''} | ${region || ''}`
          : state,
      disabled:
        state !== EngineState.PROVISIONED && state !== EngineState.PROVISIONING,
    };
  });

  const allOptions = [...[createItem], ...options];

  return (
    <Dropdown<Option>
      isLoading={!isLoaded && isLoading}
      onOpen={focusInput}
      triggerElement={
        <SelectTrigger
          triggerText={selectedEngine || 'Select engine...'}
          tooltipText={selectedEngine || ''}
          testIdPrefix='engine-select'
          label={'Engine'}
          textColor={selectedEngine ? 'text-black' : 'text-gray-500'}
          fontWeight={selectedEngine ? 'font-semibold' : 'font-normal'}
        />
      }
      OptionComponent={ResourceOption}
      selected={selectedEngine}
      onSelect={handleSelect}
      testIdPrefix='engine-select'
      options={allOptions}
      estimateSize={estimateSize}
    >
      {children}
    </Dropdown>
  );
});
