import { invoke, keys } from 'lodash-es';

export const noSpacesRegex = /^\S+$/;
export const startEndRegex = /^[^ +./:=_-].*[^ +./:=_-]$|^[^ +./:=_-]$/;
export const specialCharsRegex = /^[\w +./:=-]+$/;

export enum validationErrors {
  NO_SPACES = 'No empty spaces allowed',
  MIN_LENGTH = 'Minimum name length is 3 characters',
  MAX_LENGTH = 'Maximum name length is 63 characters',
  UNIQUE_NAME = 'Name already exists. Please choose different name.',
  INVALID_CHARS = 'Only alphanumeric, space, +, -, /, ., :, _ characters are allowed',
  START_END_INVALID_CHARS = 'Cannot start or end with special characters or spaces',
}

export const noSpacesValidator = {
  noSpaces: (v: string) => noSpacesRegex.test(v) || validationErrors.NO_SPACES,
};

export const minNameLengthValidator = {
  minimumNameLength: (name: string) =>
    name.length >= 3 || validationErrors.MIN_LENGTH,
};

export const maxNameLengthValidator = {
  maximumNameLength: (name: string) =>
    name.length <= 63 || validationErrors.MAX_LENGTH,
};

export const nameLengthValidator = {
  ...minNameLengthValidator,
  ...maxNameLengthValidator,
};

export const specialCharsValidator = {
  specialChars: (v: string) =>
    specialCharsRegex.test(v) || validationErrors.INVALID_CHARS,
};

export const resourceNameValidator = {
  ...specialCharsValidator,
  trailing: (v: string) =>
    startEndRegex.test(v) || validationErrors.START_END_INVALID_CHARS,
};

export const uniqueNameValidator = (names?: string[]) => ({
  uniqueNames: (name: string) =>
    !names?.includes(name) || validationErrors.UNIQUE_NAME,
});

export const compositeValidator = (names?: string[]) => ({
  ...maxNameLengthValidator,
  ...resourceNameValidator,
  ...specialCharsValidator,
  ...uniqueNameValidator(names),
});

export const modelNameValidator = (modelNames?: string[]) => ({
  // Breaks the path to names and uses the compositeValidator to validate them. Stops on first issue found.
  pathValidator: (modelName: string) => {
    const modelPath = modelName.split('/');
    let result: string | boolean = true;

    modelPath.forEach(currentName => {
      const validators = compositeValidator(modelNames);

      keys(validators).every(key => {
        const name = key === 'uniqueNames' ? modelName : currentName;

        const output = invoke(validators, key, name);

        if (output !== true) {
          result = `${name ? name + ':' : ''} ${output}`;

          return false;
        } else {
          return true;
        }
      });
    });

    return result;
  },
});
