import { useFlags } from 'launchdarkly-react-client-sdk';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FiCpu } from 'react-icons/fi';

import { useEngineStore } from '@relationalai/console-state';
import { EngineSize } from '@relationalai/rai-sdk-javascript/web';
import {
  Button,
  ErrorAlert,
  ErrorMessage,
  Form,
  Input,
  Label,
  Select,
} from '@relationalai/ui';

import {
  nameLengthValidator,
  noSpacesValidator,
  resourceNameValidator,
} from '../../utils/validatorUtils';
import { Modal } from '../modals/Modal';

const sizeOptions = Object.values(EngineSize).map(size => ({
  label: size,
  value: size,
}));

export type CreateFormValues = {
  name: string;
  size: EngineSize;
  version?: string;
};

type CreateEngineModalProps = {
  accountId: string;
  name?: string;
  onClose: () => void;
  onModify?: (name: string, size: string) => void;
};

export const CreateEngineModal = observer(function CreateEngineModal({
  accountId,
  name,
  onClose,
  onModify,
}: CreateEngineModalProps) {
  const { createEngineCustomHeaders } = useFlags();
  const engineStore = useEngineStore(accountId);
  const methods = useForm<CreateFormValues>({
    defaultValues: {
      name: name,
      size: EngineSize.XS,
    },
  });
  const [error, setError] = useState<Error>();
  const setFocus = methods.setFocus;

  useEffect(() => {
    setFocus('name');
  }, [setFocus]);

  const handleSubmit = async (values: CreateFormValues) => {
    try {
      await engineStore.createEngine(values.name, values.size, values.version);
      onModify && onModify(values.name, values.size);
      onClose();
    } catch (error: any) {
      setError(error);
    }
  };

  const buttons = (
    <div className='text-right space-x-4'>
      <Button
        type='secondary'
        onClick={onClose}
        disabled={methods.formState.isSubmitting}
        data-testid='cancel-button'
      >
        Cancel
      </Button>
      <Button
        onClick={methods.handleSubmit(handleSubmit)}
        loading={methods.formState.isSubmitting}
        data-testid='submit-button'
      >
        Create
      </Button>
    </div>
  );

  return (
    <Modal
      Icon={FiCpu}
      onClose={onClose}
      title='Create Engine'
      footer={buttons}
      dataTestId='create-engine-modal'
    >
      <Form hookMethods={methods} onSubmit={handleSubmit}>
        <div className='flex flex-col gap-4'>
          <div className='w-full'>
            <Label name='name' label='Name' />
            <Input
              name='name'
              placeholder='Enter engine name...'
              regOptions={{
                validate: {
                  ...noSpacesValidator,
                  ...nameLengthValidator,
                  ...resourceNameValidator,
                },
              }}
            />
            <ErrorMessage name='name' />
          </div>
          <div className='w-full'>
            <Label name='size' label='Engine Size' />
            <Select
              rules={{ required: true }}
              name='size'
              options={sizeOptions}
            />
            <ErrorMessage name='size' />
          </div>
          {createEngineCustomHeaders && (
            <div className='w-full'>
              <div className='flex gap-2'>
                <Label name='version' label='Engine Version' />
                <span className='text-gray-400 text-xs pt-[3px]'>
                  (Optional)
                </span>
              </div>
              <Input
                name='version'
                placeholder='Enter x-rai-parameter-compute-version hash...'
              />
              <ErrorMessage name='size' />
            </div>
          )}
          {error && <ErrorAlert error={error} />}
        </div>
      </Form>
    </Modal>
  );
});
