import { Option } from './ResourceOption';

export const estimateSize = (option: Option, lineWidth: number) => {
  const strLength = option.value.length;
  const lineHeight = 20;
  const restHeight = 32;
  const widthPerChar = 8;
  const restWidth = 46;

  const estimatedCharPerLine = (lineWidth - restWidth) / widthPerChar;

  const estimatedSize =
    restHeight + Math.ceil(strLength / estimatedCharPerLine) * lineHeight;

  return estimatedSize;
};
