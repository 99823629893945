import '@relationalai/ui/styles/ag-grid.css';
import 'ag-grid-enterprise';

import {
  ColDef,
  GetContextMenuItemsParams,
  ICellRendererParams,
  RowClickedEvent,
} from 'ag-grid-community';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import classNames from 'classnames';
import { castArray } from 'lodash-es';
import { Ref } from 'react';

import { Spinner } from '@relationalai/ui';
import { copyToClipboard } from '@relationalai/utils';

import { columnTypes, defaultColDef } from './columns';
import { NoDataFound } from './NoDataFound';

export type ListGridDataLoadingRow = {
  isLoadingRow: boolean;
};

function isLoadingRow(row?: any): row is ListGridDataLoadingRow {
  return !!row && 'isLoadingRow' in row;
}

type ListGridPropsBasic<T> = AgGridReactProps<T> & {
  enableLoadingRows?: never;
};

type ListGridPropsWithLoadingRows<T> = AgGridReactProps<
  T | ListGridDataLoadingRow
> & {
  enableLoadingRows: true;
};

type ListGridProps<T> = (
  | ListGridPropsBasic<T>
  | ListGridPropsWithLoadingRows<T>
) & {
  isLoading?: boolean;
  gridRef?: Ref<AgGridReact>;
};

export function ListGrid<T>({
  isLoading,
  onRowClicked,
  enableLoadingRows,
  gridRef,
  ...props
}: ListGridProps<T>) {
  const showLoadingOverlay = isLoading;
  const showNoDataOverlay = !showLoadingOverlay && props.rowData?.length === 0;

  const onRowClickedWrapper = (event: RowClickedEvent) => {
    const selection = window.getSelection()?.toString();

    if (!selection?.length) {
      onRowClicked && onRowClicked(event);
    }
  };

  const defaultColumnType: ColDef<T> = {
    ...(defaultColDef as any),
    resizable: false,
  };

  if (enableLoadingRows) {
    defaultColumnType.cellRendererSelector = params => {
      const { data, colDef } = params;

      if (isLoadingRow(data)) {
        return {
          component: (props: ICellRendererParams) => {
            return (
              props.colDef?.field && (
                <div
                  className='w-hull h-full flex items-center'
                  data-testid={`${props.colDef?.field}-loading`}
                >
                  <div className='w-full py-1.5 rounded-md animate-pulse bg-gray-300'></div>
                </div>
              )
            );
          },

          params,
        };
      }

      return {
        component: colDef?.cellRenderer,
        params,
      };
    };
  }

  return (
    <div
      className='flex h-full flex-col gap-3'
      data-testid={`table-${isLoading ? 'loading' : 'loaded'}`}
    >
      <div className='flex-1 relative h-full w-full overflow-hidden'>
        <AgGridReact
          ref={gridRef}
          className={classNames(
            'ag-theme-alpine',
            showLoadingOverlay ? 'opacity-50 pointer-events-none' : '',
          )}
          columnTypes={columnTypes}
          defaultColDef={defaultColumnType}
          getContextMenuItems={(params: GetContextMenuItemsParams) => {
            const colType = params.column?.getColDef()?.type;

            return colType && castArray(colType).includes('button')
              ? [] // Button columns got no right click context menu by default
              : [
                  {
                    name: 'Copy Cell Value',
                    action: () => copyToClipboard(params.value),
                  },
                  {
                    name: 'Copy Selected Text',
                    disabled: !window.getSelection()?.toString(),
                    action: () => {
                      const selectedText = window.getSelection()?.toString();

                      selectedText && copyToClipboard(selectedText);
                    },
                  },
                  // 'copyWithHeaders',
                ]; // Other columns default context menu options
          }}
          tooltipShowDelay={800}
          suppressCellFocus // Keyboard events are suppressed as well. Space would not select row etc
          suppressMovableColumns
          suppressFieldDotNotation
          suppressScrollOnNewData
          suppressNoRowsOverlay
          enableCellTextSelection
          rowClass='cursor-pointer'
          onRowClicked={onRowClickedWrapper}
          {...(props as ListGridPropsBasic<T>)}
        />
        {showLoadingOverlay && (
          <div className='absolute inset-0 flex h-full items-center justify-center pb-20'>
            <Spinner size='xl' />
          </div>
        )}
        {showNoDataOverlay && (
          <div className='absolute inset-0 flex h-full items-center justify-center'>
            <NoDataFound />
          </div>
        )}
      </div>
    </div>
  );
}
