import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { omit } from 'lodash-es';
import { Fragment, useRef, useState } from 'react';
import { BsSearch } from 'react-icons/bs';
import { CiKeyboard } from 'react-icons/ci';
import { HiCode } from 'react-icons/hi';
import { RiCommandLine } from 'react-icons/ri';
import { TfiClose } from 'react-icons/tfi';

import { Button, Tooltip } from '@relationalai/ui';

import {
  displayKey,
  Keybindings,
  KeySequence,
  useKeybindings,
} from '../../hooks/useKeybindings';
import { ListGrid } from '../grid/ListGrid';
import { Shortcut } from '../shortcuts/Shortcut';
import { Modal } from './Modal';

type ShortcutItem = KeySequence & {
  type: string;
};

type KeyboardShortcutsProps = {
  onClose: () => void;
};

type SectionIcon = {
  type: keyof typeof Keybindings;
};

const IconMap = ({ type }: SectionIcon) => {
  const className = 'w-6 h-6';

  switch (type) {
    case 'editor':
      return <HiCode className={className} />;
    default:
      return <CiKeyboard className={className} />;
  }
};

export function KeyboardShortcuts({ onClose }: KeyboardShortcutsProps) {
  const { editorMovableBottomPanel, tabBackAndForthShortKeys } = useFlags();
  const quickFilterRef = useRef<HTMLInputElement>(null);
  const [quickFilter, setQuickFilter] = useState('');

  useKeybindings(Keybindings.general.searchFocus, () =>
    quickFilterRef?.current?.focus(),
  );

  const rowData = Object.entries(Keybindings).reduce(
    (result: ShortcutItem[], current) => {
      const type = current[0];
      let keys = current[1];

      // TODO: Remove when these flags are deprecated
      if (type === 'editor') {
        if (!tabBackAndForthShortKeys) {
          keys = omit(keys, 'cycleTabsForward', 'cycleTabsBackwards');
        }

        if (!editorMovableBottomPanel) {
          keys = omit(keys, 'toggleBottomPanelLocation');
        }
      }

      const pairs = Object.values(keys);

      for (const pair of pairs) {
        result.push({ type, ...pair });
      }

      return result;
    },
    [],
  );

  const columns: ColDef<ShortcutItem>[] = [
    {
      field: 'type',
      width: 150,
      tooltipValueGetter: () => '',
      cellRenderer: (props: ICellRendererParams<ShortcutItem>) => (
        <div className='flex items-center h-full capitalize gap-3'>
          <IconMap type={props.value} /> {props.value}
        </div>
      ),
    },
    {
      field: 'description',
      flex: 1,
      cellRenderer: (props: ICellRendererParams<ShortcutItem>) => (
        <div className='flex items-center h-full'>{props.value}</div>
      ),
    },
    {
      field: 'keys',
      type: ['stringArray'],
      headerName: 'SHORTCUT',
      tooltipValueGetter: () => '',
      cellRenderer: (props: ICellRendererParams<ShortcutItem>) => {
        const keys = props.data?.keys || [];

        return (
          <div className='h-full flex gap-2 items-center'>
            {keys.map((key, index) => (
              <Fragment key={key}>
                <div className='border border-slate-200 bg-slate-100 text-gray-500 rounded-md font-sans px-[0.58rem] text-[15px] h-8 flex items-center justify-center'>
                  {displayKey(key)}
                </div>
                {index !== keys.length - 1 && (
                  <div
                    key={`ws-${index}-`}
                    className='flex flex-col justify-center text-gray-400'
                  >
                    +
                  </div>
                )}
              </Fragment>
            ))}
          </div>
        );
      },
    },
  ];

  return (
    <Modal
      Icon={RiCommandLine}
      title='Keyboard Shortcuts'
      onClose={onClose}
      className='max-w-4xl h-[56.5rem]'
      footer={
        <div className='text-right'>
          <Button type='secondary' onClick={onClose} data-testid='close-button'>
            Close
          </Button>
        </div>
      }
    >
      <div role='toolbar'>
        <Tooltip
          text={`Filter all values that contain ${
            quickFilter.length ? '"' + quickFilter + '"' : '...'
          }`}
          placement='right'
        >
          <div className='flex items-center border rounded-md pl-3 w-[20rem]'>
            <BsSearch className='h-4 w-4 flex-none text-gray-400' />
            <input
              name='search'
              value={quickFilter}
              ref={quickFilterRef}
              placeholder='Quick Filter...'
              data-testid='quick-filter-input'
              data-dd-action-name='KeyboardShortcuts Quick Filter Search'
              onChange={({ target: { value } }) => setQuickFilter(value)}
              className='text-sm focus:bg-none focus:outline-none placeholder:text-gray-400 w-full h-9 pl-3 pr-2'
            />
            {!quickFilter?.length ? (
              <Shortcut keySequence={Keybindings.general.searchFocus} />
            ) : null}
            {quickFilter?.length ? (
              <button className='h-full w-8' onClick={() => setQuickFilter('')}>
                <TfiClose className='w-4 h-3 cursor-pointer' />
              </button>
            ) : null}
          </div>
        </Tooltip>
      </div>
      <div className='space-y-4 pt-3 h-[calc(100%-2.5rem)] overflow-auto'>
        <ListGrid
          quickFilterText={quickFilter}
          rowData={rowData}
          columnDefs={columns}
          getMainMenuItems={() => ['pinSubMenu', 'resetColumns']}
          suppressRowVirtualisation
          getContextMenuItems={() => []}
          rowHeight={50}
        />
      </div>
    </Modal>
  );
}
