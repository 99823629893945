import { useAuth0 } from '@auth0/auth0-react';
import { intersection } from 'lodash-es';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { UserRole } from '@relationalai/console-state';

import { RaiUser, ROLES_KEY } from './raiUser';

export type RoleGuardProps = {
  roles: UserRole[];
  redirect?: boolean;
  children: JSX.Element;
};

function isRoleMatch(userRoles: string[], roles: string[]) {
  return intersection(userRoles, roles).length > 0;
}

export function RoleGuard({ roles, redirect, children }: RoleGuardProps) {
  const router = useRouter();
  const { user } = useAuth0<RaiUser>();
  const userRoles = user?.[ROLES_KEY];

  useEffect(() => {
    if (userRoles && redirect && !isRoleMatch(userRoles, roles)) {
      router.replace('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles, redirect]);

  if (!userRoles || !isRoleMatch(userRoles, roles)) {
    return null;
  }

  return children;
}
