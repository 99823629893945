import { capitalize } from 'lodash-es';
import type { DependencyList } from 'react';
import { useMemo } from 'react';
import { isIOS, isMacOs } from 'react-device-detect';
import { useHotkeys } from 'react-hotkeys-hook';

import { logKeyStroke } from '../utils/dataDog';

type KeyType = {
  apple: string;
  other?: string;
};

type KeyboardModifiers = {
  alt?: boolean;
  ctrl?: boolean;
  shift?: boolean;
  mod?: boolean;
};

export type KeySequence = {
  description: string;
  keys: string[];
};

export declare type Hotkey = KeyboardModifiers & {
  keys?: string[];
  scopes?: string | string[];
};
type HotkeysEvent = Hotkey & {};

export const KeyConversion: Record<string, KeyType> = {
  meta: {
    apple: '⌘',
    other: 'ctrl',
  },
  alt: {
    apple: '⌥',
    other: 'alt',
  },
  shift: {
    apple: '⇧',
    other: 'shift',
  },
  period: {
    apple: '.',
  },
  comma: {
    apple: ',',
  },
};

export const Keybindings: Record<string, Record<string, KeySequence>> = {
  general: {
    searchFocus: {
      description: 'Search',
      keys: ['meta', 'k'],
    },
    showShortcuts: {
      description: 'Show Keyboard Shortcuts',
      keys: ['meta', 'u'],
    },
  },
  editor: {
    addNewTab: {
      description: 'Add New Tab',
      keys: ['shift', 'alt', 't'],
    },
    closeActiveTab: {
      description: 'Close Active Tab',
      keys: ['shift', 'alt', 'w'],
    },
    runOrSaveActiveTab: {
      description: 'Run/Save Active Tab',
      keys: ['shift', 'enter'],
    },
    cycleTabsForward: {
      description: 'Switch To The Next Open Tab',
      keys: ['shift', 'alt', 'period'],
    },
    cycleTabsBackwards: {
      description: 'Switch To The Previous Open Tab',
      keys: ['shift', 'alt', 'comma'],
    },
    cycleOutputFormats: {
      description: 'Cycle Output Formats (Output Tab Must Be Active)',
      keys: ['shift', 'alt', 'v'],
    },
    toggleLeftPanel: {
      description: 'Toggle Left Panel Visibility',
      keys: ['shift', 'alt', 'l'],
    },
    toggleBottomPanel: {
      description: 'Toggle Bottom Panel Visibility',
      keys: ['shift', 'alt', 'b'],
    },
    toggleBottomPanelLocation: {
      description: 'Toggle Bottom Panel Location (Bottom/Right)',
      keys: ['shift', 'alt', 'r'],
    },
    activateOutputPanel: {
      description: 'Activate Output Panel (When Bottom Panel is Visible)',
      keys: ['shift', 'alt', 'o'],
    },
    activateProblemsPanel: {
      description: 'Activate Problems Panel (When Bottom Panel is Visible)',
      keys: ['shift', 'alt', 'p'],
    },
    activateTransactionPanel: {
      description:
        'Activate Transaction Details Panel (When Bottom Panel is Visible)',
      keys: ['shift', 'alt', 'd'],
    },
  },
};

export const convertSequence = (keys: string[], isAppleOS: boolean) =>
  keys.map(key => {
    if (!isAppleOS) {
      const conversion = KeyConversion[key]?.other;

      return conversion ? conversion : key;
    }

    return key;
  });

export const displayKey = (key: string): string => {
  if (KeyConversion[key]) {
    return capitalize(
      isIOS || isMacOs
        ? KeyConversion[key].apple
        : KeyConversion[key].other || KeyConversion[key].apple,
    );
  }

  return capitalize(key);
};

export function useKeybindings(
  sequence: KeySequence,
  callback: (keyboardEvent: KeyboardEvent, hotkeysEvent: HotkeysEvent) => void,
  dependencies?: DependencyList,
  autoLog = true,
) {
  const keySequence = useMemo(
    () => convertSequence(sequence.keys, isIOS || isMacOs).join('+'),
    [sequence.keys],
  );
  const options = {
    preventDefault: true,
    enableOnContentEditable: true,
  };

  useHotkeys(
    keySequence,
    autoLog
      ? () => logKeyStroke(keySequence, sequence.description, callback)
      : callback,
    options,
    dependencies,
  );
}
