import { ColTypeDef, SortChangedEvent, SortModelItem } from 'ag-grid-community';
import {
  isArray,
  isDate,
  isEmpty,
  isNumber,
  isString,
  startCase,
  toUpper,
} from 'lodash-es';

import { toLocaleDateTimeString } from '@relationalai/utils';

export const defaultColDef: ColTypeDef = {
  filter: false,
  sortable: true,
  unSortIcon: false,
  suppressHeaderMenuButton: true,
  suppressHeaderFilterButton: true,
  menuTabs: [],
  // menuTabs: ['filterMenuTab', 'generalMenuTab'],
  tooltipValueGetter: (params: any) => params?.value || '',
  headerValueGetter: (params: any) => {
    const { colDef } = params;

    if (!colDef.headerName) {
      return toUpper(startCase(colDef.headerName || colDef?.field));
    }

    return colDef.headerName;
  },
};

export const columnTypes: { [key: string]: ColTypeDef } = {
  // Main column in the gird
  accent: {
    flex: 1,
    minWidth: 320,
    cellClass: 'font-bold',
  },
  // Cell button column (like Delete/Cancel)
  button: {
    width: 50,
    filterParams: {
      readOnly: true,
    },
    sortable: false,
    resizable: false,
    unSortIcon: false,
    suppressMenu: true,
    floatingFilter: false,
    suppressMovable: true,
    cellClass: 'btn-cell',
  },
  stringArray: {
    valueFormatter: ({ value }) => (isArray(value) ? value.join(', ') : ''),
    tooltipValueGetter: ({ value }) => (isArray(value) ? value.join(', ') : ''),
  },
  // Date column
  dateColumn: {
    minWidth: 180,
    filter: 'agDateColumnFilter',
    valueFormatter: (params: any) => toLocaleDateTimeString(params.value),
    valueGetter: (params: any) => {
      if (params.colDef.field) {
        const value = params.data[params.colDef.field];

        if (isDate(value)) {
          return value;
        }

        if ((isString(value) && !isEmpty(value)) || isNumber(value)) {
          return new Date(value);
        }

        return undefined;
      }
    },
    filterParams: {
      suppressAndOrCondition: true,
    },
  },
};

export const getSortModels = (event: SortChangedEvent): SortModelItem[] => {
  return (
    event.api
      .getColumns()
      ?.map(col =>
        col.getSort()
          ? {
              colId: col.getColId(),
              sort: col.getSort(),
            }
          : undefined,
      )
      .filter((s): s is SortModelItem => !!s) ?? []
  );
};
