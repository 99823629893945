import { Placement } from '@floating-ui/react';
import classNames from 'classnames';
import { RiArrowDownSLine } from 'react-icons/ri';

import { Tooltip } from '@relationalai/ui';

export type TriggerProps = {
  triggerText: string;
  label: string;
  textColor?: string;
  fontWeight?: string;
  testIdPrefix: string;
  tooltipText: string;
  tooltipPlacement?: Placement;
  disabled?: boolean;
};

export function SelectTrigger({
  triggerText,
  label,
  textColor = 'text-black',
  fontWeight = 'font-semibold',
  testIdPrefix,
  tooltipText,
  tooltipPlacement = 'bottom',
  disabled = false,
}: TriggerProps) {
  return (
    <Tooltip text={tooltipText} placement={tooltipPlacement}>
      <div
        data-testid={`${testIdPrefix}-trigger`}
        className={classNames(
          'relative bg-white w-64 h-full rounded-md  focus:outline-none',
          'pl-4 pr-10 py-1 text-left',
          disabled && 'cursor-not-allowed',
          !disabled && 'hover:bg-gray-50',
        )}
      >
        <div className='flex flex-col'>
          <span className='text-xs text-bold tracking-wider text-gray-400'>
            {label}
          </span>

          <span
            className={classNames(
              'flex-1 truncate text-sm',
              `${textColor}`,
              `${fontWeight}`,
            )}
          >
            {triggerText}
          </span>
        </div>

        <span className='absolute inset-y-0 right-0 flex items-center pr-2'>
          <RiArrowDownSLine
            className={classNames('h-5 w-5 text-gray-400')}
            aria-hidden='true'
          />
        </span>
      </div>
    </Tooltip>
  );
}
