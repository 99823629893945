import classNames from 'classnames';

import { Dropdown, OptionComponentProps } from '@relationalai/ui';

export type User = {
  name: string;
  email: string;
  picture: string;
};

type UserSelectProps = {
  user: User;
  logout: () => void;
};

export function UserSelect({ user, logout }: UserSelectProps) {
  const triggerElement = (
    <>
      <span className='sr-only'>Open user menu</span>
      <img
        className='h-8 w-8 rounded-full bg-white
                  group-focus:ring-2 focus:ring-offset-2 group-focus:ring-red-orange-500
                  group-disabled:cursor-not-allowed'
        src={user.picture}
        alt=''
      />
    </>
  );

  const options = [
    {
      label: user.name,
      value: user.name,
      subText: user.email,
      disabled: true,
    },
    {
      label: 'Sign Out',
      value: 'Sign Out',
    },
  ];

  return (
    <Dropdown<UserOption>
      triggerElement={triggerElement}
      testIdPrefix='user-select'
      OptionComponent={UserSelectOption}
      options={options}
      onSelect={logout}
      width='auto'
    />
  );
}

type UserOption = {
  label: string;
  value: string;
  subText?: string;
  disabled?: boolean;
  active?: boolean;
};

const UserSelectOption = ({
  option,
  active,
}: OptionComponentProps<UserOption>) => {
  const { label, subText, disabled } = option;

  const defaultProps = {
    'aria-label': label,
    'data-testid': `user-select-option-${label}`,
    className: classNames(
      'px-4 py-2 w-full flex flex-col text-left text-sm font-medium cursor-pointer',
      !disabled && 'hover:bg-red-orange-100',
      active && 'bg-red-orange-100',
    ),
  };

  return (
    <span {...defaultProps}>
      <span className='break-all'>{label}</span>

      {subText && <span className='text-gray-500'>{subText}</span>}
    </span>
  );
};
