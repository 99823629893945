import { useAuth0 } from '@auth0/auth0-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { compact } from 'lodash-es';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { FiCpu } from 'react-icons/fi';
import { HiCode } from 'react-icons/hi';
import {
  RiCommandLine,
  RiComputerLine,
  RiDatabase2Line,
  RiSettings2Line,
  RiTimeLine,
} from 'react-icons/ri';

import { UserRole } from '@relationalai/console-state';
import { WorksheetsIcon } from '@relationalai/ui';
import { PAGE_TITLE_SUFFIX } from '@relationalai/ui/constants';

import useAccount from '../../hooks/useAccount';
import useHasMounted from '../../hooks/useHasMounted';
import { Keybindings, useKeybindings } from '../../hooks/useKeybindings';
import useModal from '../../hooks/useModal';
import { RaiUser } from '../auth/raiUser';
import withRaiAuthentication from '../auth/withRaiAuthentication';
import { KeyboardShortcuts } from '../modals/KeyboardShortcuts';
import { UpdateBanner } from '../UpdateBanner';
import { ClaimsLoader } from './ClaimsLoader';
import { ErrorBoundary } from './ErrorBoundary';
import NavBar from './NavBar';
import Sidebar, { NavItem } from './Sidebar';
import { User } from './UserSelect';

type LoginLayoutRawProps = {
  children: JSX.Element;
  pageTitle?: string;
};

function LoginLayoutRaw({ pageTitle, children }: LoginLayoutRawProps) {
  const hasMounted = useHasMounted();
  const { user, logout } = useAuth0<RaiUser>();

  if (!hasMounted) {
    return null;
  }

  const logoutAndRedirect = () => {
    logout({ returnTo: window.location.origin });
  };

  return (
    <Layout
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      user={user!}
      logout={logoutAndRedirect}
      pageTitle={pageTitle}
    >
      <ErrorBoundary>{children}</ErrorBoundary>
    </Layout>
  );
}

export const LoginLayout = withRaiAuthentication(LoginLayoutRaw);

type LayoutProps = {
  children: JSX.Element;
  user: User;
  logout: () => void;
  pageTitle?: string;
};

function Layout({ children, user, logout, pageTitle }: LayoutProps) {
  const router = useRouter();
  const { accountId } = useAccount();
  const { skaffoldPage } = useFlags();
  const { openModal } = useModal(onClose => (
    <KeyboardShortcuts onClose={onClose} />
  ));

  useKeybindings(Keybindings.general.showShortcuts, openModal);

  const navigation: NavItem[] = compact([
    {
      name: 'Engines',
      href: `/${accountId}/engines`,
      icon: FiCpu,
      testId: 'engines',
      roles: [UserRole.ADMIN, UserRole.USER, UserRole.USER_READ_ONLY],
    },
    {
      name: 'Databases',
      href: `/${accountId}/databases`,
      icon: RiDatabase2Line,
      testId: 'databases',
      current: false,
      roles: [UserRole.ADMIN, UserRole.USER, UserRole.USER_READ_ONLY],
    },
    {
      name: 'Worksheets',
      href: `/${accountId}/worksheets`,
      icon: WorksheetsIcon,
      testId: 'worksheets',
      roles: [UserRole.ADMIN, UserRole.USER, UserRole.USER_READ_ONLY],
    },
    {
      name: 'Editor',
      href: `/${accountId}/editor`,
      testId: 'editor',
      icon: HiCode,
      roles: [UserRole.ADMIN, UserRole.USER, UserRole.USER_READ_ONLY],
    },
    {
      name: 'Transactions',
      href: `/${accountId}/transactions`,
      icon: RiTimeLine,
      testId: 'transactions',
      roles: [UserRole.ADMIN, UserRole.USER, UserRole.USER_READ_ONLY],
    },
    {
      name: 'Settings',
      href: `/${accountId}/settings`,
      icon: RiSettings2Line,
      testId: 'settings',
      roles: [UserRole.ADMIN, UserRole.SYS_ADMIN],
    },
    // Skaffold should always be last
    skaffoldPage && {
      name: 'Skaffold',
      href: `/${accountId}/skaffold`,
      icon: RiComputerLine,
      testId: 'skaffold',
      roles: [UserRole.ADMIN, UserRole.USER, UserRole.USER_READ_ONLY],
    },
    // Bottom section (bottom === true)
    {
      name: 'Keyboard Shortcuts',
      testId: 'hotkeys',
      icon: RiCommandLine,
      onClick: openModal,
      bottom: true,
      roles: [UserRole.ADMIN, UserRole.USER, UserRole.USER_READ_ONLY],
    },
  ]);

  navigation.forEach(
    navItem =>
      (navItem.current =
        !!navItem.href && router.asPath.startsWith(navItem.href)),
  );

  return (
    <>
      {pageTitle && (
        <Head>
          <title>{`${pageTitle} ${PAGE_TITLE_SUFFIX}`}</title>
        </Head>
      )}
      <div className='h-screen flex flex-col overflow-hidden relative z-0'>
        <UpdateBanner />
        <NavBar user={user} navItems={navigation} logout={logout} />

        <div className='min-h-0 flex-1 flex overflow-hidden'>
          <Sidebar navItems={navigation} />
          <div className='flex flex-col w-0 flex-1 overflow-hidden'>
            <main className='flex-1 relative overflow-y-auto'>
              <ClaimsLoader>{children}</ClaimsLoader>
            </main>
          </div>
        </div>
      </div>
    </>
  );
}
