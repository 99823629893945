import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { useRouter } from 'next/router';
import { ComponentType, FunctionComponent, useEffect } from 'react';

import { useUserAnalytics } from '../../hooks/useAnalytics';
import { AuthPanel } from './AuthPanel';
import { RaiUser } from './raiUser';

/**
 * A wrapper around Auth0's withAuthenticationRequired. We do this so we can
 * have consistent redirect panels and redirects to the login page when there
 * are authentication errors.
 *
 * @param {React.Component} Component
 * @returns {React.Component}
 */
export default function withRaiAuthentication<
  T extends object,
  P extends object
>(Component: ComponentType<T>) {
  const SecureLoginProvider = withAuthenticationRequired(Component, {
    onRedirecting: () => (
      <AuthPanel>
        <div>
          <h2 className='text-md font-medium text-center'>
            Redirecting to login...
          </h2>
        </div>
      </AuthPanel>
    ),
  });

  /**
   * This component is responsible for redirecting to the /login route when
   * authentication has already failed and you try to access a secured route.
   */
  return function WithRAIAuthentication(props: T) {
    const router = useRouter();
    const { error } = useAuth0<RaiUser>();

    useUserAnalytics();

    useEffect(() => {
      if (error) {
        router.push('/login');
      }
    });

    if (error) {
      // render nothing as the useEffect hook will redirect us to /login
      return null;
    }

    return <SecureLoginProvider {...props} />;
  } as FunctionComponent<T> & P;
}
