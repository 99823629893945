import { Transition } from '@headlessui/react';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import useConfirm from '../hooks/useConfirm';

const timeIntervalMS = 6 * 60 * 60 * 1000; // 6 hours in ms
const initVersion = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA;

async function fetchVersion() {
  try {
    const res = await fetch('/api/version');
    const data = await res.json();

    return data.version as string;
  } catch {
    return undefined;
  }
}

export function UpdateBanner() {
  const router = useRouter();
  const confirm = useConfirm();
  const [version, setVersion] = useState<string | undefined>();

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const v = await fetchVersion();

      setVersion(v);
    }, timeIntervalMS);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const isBannerVisible = !!version && version !== initVersion;

  const handleUpdate = async () => {
    const confirmMessage = (
      <span>
        Are you sure that you want to update the page? If you have unsaved
        changes they will be lost.
      </span>
    );

    const isConfirmed = await confirm(confirmMessage, 'Update');

    if (isConfirmed) {
      router.reload();
    }
  };

  return (
    <Transition
      show={isBannerVisible}
      enter='transition ease-out duration-100'
      enterFrom='transform opacity-0 scale-95'
      enterTo='transform opacity-100 scale-100'
      leave='transition ease-in duration-75'
      leaveFrom='transform opacity-100 scale-100'
      leaveTo='transform opacity-0 scale-95'
    >
      <div className='text-blue-400 bg-blue-50 flex flex-row justify-center items-center p-3 border-gray-300 border-b'>
        <p className='mr-4'>A new version of the Console is available.</p>

        <button
          className='text-blue-400 uppercase font-bold text-md hover:text-blue-600 transition-all duration-200'
          type='button'
          onClick={handleUpdate}
        >
          Update
        </button>
      </div>
    </Transition>
  );
}
