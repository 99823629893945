import classNames from 'classnames';
import React, { FC, SVGProps } from 'react';
import { RiCheckLine } from 'react-icons/ri';

import { OptionComponentProps } from '@relationalai/ui';

export type Option = {
  label: string;
  value: string;
  subText?: string;
  icon?: FC<SVGProps<SVGSVGElement>>;
  disabled?: boolean;
  animate?: boolean;
};

export function ResourceOption({
  option,
  selected,
  active,
}: OptionComponentProps<Option>) {
  const { label, subText, disabled, icon, animate } = option;

  const Icon = icon;

  const defaultProps = {
    'aria-label': label,
    'data-testid': `resource-option-${label}`,
    className: classNames('px-3 py-2 w-full block text-left cursor-pointer', {
      'cursor-not-allowed': disabled,
      'hover:bg-red-orange-100': !disabled,
      'text-red-orange-900': selected,
      'text-gray-600': !selected,
      'bg-red-orange-100 text-gray-900': active,
      'animate-pulse': animate,
    }),
  };

  return (
    <span {...defaultProps}>
      <span className='flex justify-between align-middle'>
        <span className='flex flex-1'>
          {Icon && (
            <Icon
              data-testid='icon'
              className='h-5 w-5 mr-3 bg-cover flex-none'
            />
          )}
          <span
            className={classNames('text-sm break-all', {
              'text-gray-400': disabled,
            })}
          >
            {label}
          </span>
        </span>

        <span className='flex-none w-5'>
          {selected && (
            <RiCheckLine
              data-testid='selected-icon'
              className='h-5 w-5 mr-2 text-red-orange-700'
              aria-hidden='true'
            />
          )}
        </span>
      </span>

      {subText && (
        <p className='text-gray-400 text-xs tracking-wider uppercase'>
          {subText}
        </p>
      )}
    </span>
  );
}
