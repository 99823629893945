import classNames from 'classnames';
import { isIOS, isMacOs } from 'react-device-detect';

import {
  convertSequence,
  displayKey,
  KeySequence,
} from '../../hooks/useKeybindings';

type ShortcutProps = {
  keySequence: KeySequence;
  size?: 'xs' | 'sm';
};

const sizeClasses = {
  xs: 'p-px text-sm',
  sm: 'p-1 text-sm',
};

const defaultClasses =
  'px-2 mr-1 border-1 rounded-sm bg-slate-100 text-gray-400';

export const Shortcut = ({ keySequence, size = 'sm' }: ShortcutProps) => {
  if (!keySequence?.keys?.length) {
    return null;
  }

  const osSequence = convertSequence(keySequence.keys, isIOS || isMacOs);

  return (
    <span
      aria-keyshortcuts={osSequence.join('+')}
      title={`${keySequence.description} key sequence`}
    >
      {osSequence.map((key, index) => (
        <span
          className={classNames(defaultClasses, sizeClasses[size])}
          key={index}
        >
          {displayKey(key)}
        </span>
      ))}
    </span>
  );
};
