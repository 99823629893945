import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { Fragment, ReactNode, RefObject, useEffect } from 'react';
import { IconType } from 'react-icons';

type ModalProps = {
  Icon?: IconType;
  title?: ReactNode;
  children: ReactNode;
  initialFocusRef?: RefObject<HTMLElement>;
  className?: string;
  onClose: () => void;
  footer?: ReactNode;
  dataTestId?: string;
};

export function Modal({
  Icon,
  title,
  children,
  initialFocusRef,
  className = 'max-w-md align-middle relative',
  onClose,
  footer,
  dataTestId,
}: ModalProps) {
  const router = useRouter();

  useEffect(() => {
    return () => {
      onClose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname]);

  return (
    <>
      <Transition show appear as={Fragment}>
        <Dialog
          initialFocus={initialFocusRef}
          as='div'
          className='fixed inset-0 z-[10] min-h-screen flex items-center justify-center'
          onClose={onClose}
          data-testid={dataTestId}
        >
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 opacity-30 bg-gray-500' />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <Dialog.Panel
              className={classNames(
                'flex flex-col text-left transform bg-white shadow-xl rounded-lg max-h-[95vh] w-full',
                className,
              )}
            >
              <Dialog.Title
                as='h3'
                className={classNames(
                  'flex-none p-6 text-md font-medium leading-5 text-white bg-red-orange-900 rounded-t-lg',
                  'items-center flex gap-3',
                )}
              >
                {Icon && <Icon className='h-6 w-6' />}
                <span>{title}</span>
              </Dialog.Title>
              <div className='flex-1 p-6 overflow-auto'>{children}</div>
              {footer && (
                <div className='flex-none border-t max-h-[calc(50vh-10rem)] min-h-[5.5rem] overflow-auto border-gray-200 p-6'>
                  {footer}
                </div>
              )}
            </Dialog.Panel>
          </Transition.Child>
        </Dialog>
      </Transition>
    </>
  );
}
