import { ReactNode, useContext } from 'react';
import { v4 as uuid } from 'uuid';

import Confirm from '../components/modals/Confirm';
import { ModalContext } from '../components/modals/ModalProvider';

export default function useConfirm() {
  const context = useContext(ModalContext);

  return async function (
    message: ReactNode,
    confirmButtonText?: string,
    cancelButtonText?: string,
  ) {
    return new Promise(resolve => {
      const id = uuid();

      const handleConfirm = () => {
        resolve(true);
        context.closeModal(id);
      };

      const handleCancel = () => {
        resolve(false);
        context.closeModal(id);
      };

      const element = (
        <Confirm
          message={message}
          confirmButtonText={confirmButtonText}
          cancelButtonText={cancelButtonText}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      );

      context.openModal(id, element);
    });
  };
}
