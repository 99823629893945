import { ReactNode, useRef } from 'react';
import { BsQuestionCircle } from 'react-icons/bs';

import { Button } from '@relationalai/ui';

import { Modal } from './Modal';

type ConfirmProps = {
  message?: ReactNode;
  confirmButtonText?: string;
  cancelButtonText?: string;
  onConfirm: () => void;
  onCancel: () => void;
};

export default function Confirm({
  message,
  confirmButtonText = 'Confirm',
  cancelButtonText = 'Cancel',
  onConfirm,
  onCancel,
}: ConfirmProps) {
  const confirmReference = useRef<HTMLButtonElement>(null);

  const buttons = (
    <div className='text-right space-x-4'>
      <Button type='secondary' onClick={onCancel} data-testid='cancel-button'>
        {cancelButtonText}
      </Button>
      <Button
        onClick={onConfirm}
        data-testid='confirm-button'
        ref={confirmReference}
      >
        {confirmButtonText}
      </Button>
    </div>
  );

  return (
    <Modal
      Icon={BsQuestionCircle}
      title='Confirmation'
      initialFocusRef={confirmReference}
      onClose={onCancel}
      footer={buttons}
    >
      <p className='text-sm text-gray-500'>{message}</p>
    </Modal>
  );
}
